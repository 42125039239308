#timeline-zoom-button  {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    padding: 0 0.3rem;
    font-variant: small-caps;
    text-align: center;
    background-color: #000;
    border: #000 1px solid;
    color: #FFF;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
 
}

.document-type-button {
    width: 70%;
    height: 3rem;
    background-color: #000;
    border: #000 1px solid;
    color: #FFF;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    cursor: pointer;
    padding: 0 0;

    font-variant: small-caps;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
}

#tilbud-button {
    background-image: url('/public/tilbud.svg');
}
#faktura-button {
    background-image: url('/public/faktura.svg');
}
#timelist-button {
    background-image: url('/public/timelist.svg');
}
#task-button {
    background-image: url('/public/tasks.svg');
}
#kontrakt-button {
    background-image: url('/public/kontrakt.svg');
}

.document-type-status {
  height: 100%;
  width: 50%;
  position: relative;
  left: 25%;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-image: url('/public/ok.svg');
  
}


#timeline-zoom-button:nth-child(2)
{
    right: 28%;
}
#view-toggle-button {
    width: 8rem;
    height: 2rem;
    background-color: #000;
    border: #000 1px solid;
    color: #FFF;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    cursor: pointer;
    padding: 0 1rem;
    font-variant: small-caps;
    text-align: center;
}
#view-toggle-button:active {
  box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
 
}

#view-toggle-panel, #timeline-zoom-panel, #document-type-panel, #document-cover-panel {
    background-color: #DDD;
    border: #DDD 1px solid;
    color: #000;
    display: inline-block;
    padding: 0 1rem;
    font-variant: small-caps;
    text-align: center;
    padding: 1rem 0;
    height: fit-content;
}

#view-toggle-panel div, #timeline-zoom-panel div, #document-type-panel div, #document-cover-panel div {
  display: block;
  margin: 0.3rem auto;
}

#document-type-panel{
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);

    width: 100%;
    z-index: 4;


} 

#document-cover-panel{
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);

    width: 100%;

} 

#view-toggle-panel{
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);

    width: 100%;
    z-index: 4;

} 
#timeline-zoom-panel {
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);

    width: 100%;

}




#top-beam-filters{
  height: 2.3rem;
  width: 100%;
  background-color: #FFF;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin: 0;
  padding: 0;
  filter: invert(1);
}

#view-button-label{
    width: 10rem;
    color: #000;
    cursor: default;
    padding: 0 1rem;
    font-variant: small-caps;
    text-align: left;
    height: fit-content;
}

#document-card-list {
    margin-top: 3rem;
    padding-left: 3%;
    display: grid;
    column-gap: 2%;
    row-gap: 1%;
    grid-template-columns: 48% 48%;
}

#document-card-list div {
    cursor: pointer;
}

.document-list-item-header h3 {
    font-size: 70%;
}

.document-list-item-header {
    padding-top: 1.2rem;
    cursor: pointer;

}
.document-list-item-ico {
    margin-top: 5%;
    width: 2rem;
    height: 3rem;
    margin: 1rem auto;
    z-index: 3;
    background-repeat: no-repeat;
    background-size: 100%;

    cursor: pointer;
    position: relative;
}


.project-wrapper, .document-list-item{
    position: relative;
    height: 5rem;
   border-bottom: gray 2px solid;
   background-color: #EEE;
   z-index: 1;
   color: #000;
   display: grid;
   column-gap: 0%;
   grid-template-columns: 5% 20% 30% 45%;
}

.project-wrapper div{
 height: 100%;
}

.project-wrapper img:hover, .document-list-item img:hover{
    transform: scale(1.1);
    border: 2px solid #FFF 
}

.project-wrapper div:last-child {
    position: absolute;
    top: 0;
    right: 0;
    width: 22vw;
}



.new-document-buttons {
    display: inline;
  }
  
  .new-document-buttons div:first-child {
    display: inline;
  }
  
  .timelist-row {
    text-align: center;
    margin: 15px 0;
  }
  
  
  
  
  section,h1 {
    padding: 0 1em;
  }
  

  
  .suggestions{
    background-color: #FFF;
    list-style-type:none;
    cursor: pointer;
    padding-top: 1px;
    padding-left: 0.7em;
    border: 1px #000 solid;
    border-top: 0px;
    position: fixed;
    z-index: 40;
    width: 20rem;
  }
  .suggestions li{
    margin-top: 0.2em;
  
  }
  .suggestions li:hover{
    background-color: rgb(200, 200, 200);
    font-weight: 800;
  }
  
  
  #timelist-headers{
    border: 0;
    padding: 2vh 1vw;
    height: 8vh;
  }
  
  .white-arc {
    padding: 4vh 0;
    border: solid #000 0px;
    background-color: #FFF;
    z-index: 35;
  }
  
  .white-arc label h4 {
    padding-top: 5%;
  }
  
  .white-arc div {
    border: solid #000 0px;
  
  }
  .white-arc div div div div div{
    border: 0;
    padding: 2vh 1vw;
  }
  
  
  .white-arc div div div div {
    padding: 0vh 0;
  }
  
  #timelist-document, #invoice-document, #tilbud-document{
    position: fixed;
    overflow-y: auto;
    top: 8vh;
    bottom: 0;
    left: 0;
    right: 0;
    margin:  5vw;
    padding: 0rem;
    width: 90vw;
    height: auto;
    background-color: #FFF;
    z-index: 33;
  }
  

  #document-options-full-preview {
    width: 100%;
    height: fit-content;
    position: fixed;
    left: 0;
    bottom : 3rem;
    right: 0;
    z-index: 5;
  }

  #document-options-preview {
    width: 100%;
    height: fit-content;
    position: absolute;
    left: 0;
    bottom : 3rem;
    right: 0;
  }


  
  /* Handle on hover */

  ::-webkit-scrollbar {
    width: 10px;
  }/* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #777;
  }
  
  #document-background {
    position: fixed;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 30;
    padding-top: 1rem;
  }

  input{
    width: 90%;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    border: #EEE 1px solid;
    
  }

  textarea{
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
  }

  select{
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;

  }
  
   #top-beam{
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: fixed;
    top: 2rem;
    left: 0;
    right: 0;
    width: 90vw;
    margin: 0 5vw;
    height: 3rem;
    z-index: 100;
  }
  
  #top-beam h1 {
    margin-left: 1rem;
    font-variant: small-caps;
  }
  
  .timelist-top-beam {
    background-image: url('/public/timelist.svg');
    background-position-x: 0;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-color: #F5D53D;
    color:#000;
  }
  
  .faktura-top-beam {
    background-image: url('/public/faktura.svg');
    background-position-x: 0;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-color: #403DF5;
    color: #FFF;
  }
  .faktura-top-beam div {
    border-color: #FFF;
  }
  
  .tilbud-top-beam {
    background-image: url('/public/tilbud.svg');
    background-position-x: 0;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-color: #3CF084;
    color: #000;
  }

  .kreditnota-top-beam {
    background-image: url('/public/kreditnota.svg');
    background-position-x: 0;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-color: #5E5DA0;
    color: #000;
}
  
  #document-list {
    width: 50%;
    padding: 2%;
    right: 0;
    z-index: 1;
    margin-top: 3rem;
    position: absolute;

  }

  #jobbleder-watermark-doc-preview {
    background-image: url('/public/jobbleder.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
    position: absolute;
    height: 150px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 150;
  }
  
  #timelist-minimized {
    cursor: pointer;
    position: fixed;
    background-color: #000;
    background-image: url('/public/timelist.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
    border: #F5D53D 2px solid;
    color: #FFF;
    top: 2px;
    left: 14rem;
    width: 10rem;
    margin: 1 5vw;
    height: 3.4rem;
    z-index: 35;
    z-index: 5;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    animation: timelistMinimizedPulse infinite 4s;
  }
  #faktura-minimized {
    cursor: pointer;
    position: fixed;
    background-color: #000;
    background-image: url('/public/faktura.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
    border: #403DF5 1px solid;
    color: #FFF;
    top: 2px;
    left: 25rem;
    width: 10rem;
    margin: 1 5vw;
    height: 3.4rem;
    z-index: 35;
    z-index: 6;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    animation: fakturaMinimizedPulse infinite 4s;
  }
  #offer-minimized {
    cursor: pointer;
    position: fixed;
    background-color: #000;
    border: #3DF586 1px solid;
    color: #FFF;
    background-image: url('/public/tilbud.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
    top: 2px;
    left: 36rem;
    width: 10rem;
    margin: 1 5vw;
    height: 3.4rem;
    z-index: 35;
    z-index: 7;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    animation: offerMinimizedPulse infinite 4s;
  }

  @keyframes timelistMinimizedPulse {
    0% {    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4)}
    3% {    box-shadow: 5px 5px 10px #F5D53D, -5px -5px 10px rgba(255, 255, 255, 0.4)}
    70% {    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px #F5D53D}
    100% {    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4)}
  }
  
  @keyframes fakturaMinimizedPulse {
    0% {    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4)}
    3% {    box-shadow: 5px 5px 10px #403DF5, -5px -5px 10px rgba(255, 255, 255, 0.4)}
    70% {    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px #403DF5}
    100% {    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4)}
  }

  @keyframes offerMinimizedPulse {
    0% {    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4)}
    3% {    box-shadow: 5px 5px 10px #3DF586, -5px -5px 10px rgba(255, 255, 255, 0.4)}
    70% {    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px #3DF586}
    100% {    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4)}
  }

  #addday-content-preview-header{
    border: 1px solid;
    height: fit-content;
    width: 96%;
    border: 1px solid;
    text-align: center;
    margin: 0 2%;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 5% 10% 20% 10% 10% 10% 35%;
    font-variant: small-caps;
    font-size: small;
    margin-top: 1rem;
}

#addday-content-preview-header div
{
    height: 100%;
    width: 100%;
    border: #000 solid 1px;
    padding: 0.5rem 0;
}


#addday-content-preview {
  border: 1px solid;
  text-align: center;
  width: 96%;
  margin: 0 2%;
  display: grid;
  column-gap: 0%;
  grid-template-columns: 5% 10% 20% 10% 10% 10% 35%;
}

#addday-content-preview div {
  border-left: #000 dotted 1px;
  border-right: #000 dotted 1px;
  padding: 0.5rem 0;
  height: 100%;
}
  
  #invoice, #tilbud, #timelist {
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 9rem 5vw 1rem 5vw;
    padding: 2vh 2vw;
    width: 90vw;
    height: auto;
    background-color: #FFF;
    z-index: 35;
  }
  
  #invoice #document-fieldset div, #tilbud #document-fieldset div, #timelist #document-fieldset div{
    margin: 2rem 0;
  }
 
  
  #faktura-details-date{
    padding: 1rem 3rem 3rem 3rem;
  }

  #new-document-export-options{
    position: fixed;
    top: 5rem;
    left: 0;
    right: 0;
    background-color: #000;
    width: 90vw;
    height: auto;
    margin: auto auto;
    z-index: 10;
    margin: 0 5vw;
  }


  #new-document-export-options p{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-variant: small-caps;
  }

.option-knob {
    padding: 1rem;
    width: 4rem;
    height: 4rem;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 5%;
    position: relative;
    background-color: #000;
    border: #010 1px solid;
    cursor: pointer;
    color: #DDD;
    display: inline-block;
    margin: 0 0.2rem;
}
  .user-company-data {
    padding: 0;
    margin: 0;
  }
  .user-company-data h4 {
    text-align: center;
    font-size: 1.3rem;  
    line-height: normal;
    padding: 0.3rem 0;
    margin: 0;
  }

  #document-fieldset {
    margin: 2%;
    padding: 0.5rem;
    border: #000 1px solid;
    width: 96%;
  }
  .document-export-ico {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    padding: 0;
    margin: 20% auto;
    z-index: 4;
  }

h4{
  font-size: 90%;
}

  .edit-accept{
    margin: 0 33%;
  }

  .weekday-timelist{
    font-style: italic;
    font-size: smaller;
  }
  .weekday-sunday-timelist{
    font-style: italic;
    font-size: smaller;
    color: #FF0000;
  }




  @media only screen and (max-width: 600px) {



  #timeline-zoom-panel{
    visibility: hidden;
  }
  #view-toggle-panel,  #document-type-panel, #document-cover-panel {
    width: 100%;
  }
  .document-list-item{
   grid-template-columns: 10% 45% 45%;
  }

  #document-card-list {
    display: block;
      margin-top: 3rem;
      padding: 0;


  
}


  #document-card-list #document-preview{
    margin: 2rem auto;
  }

  #invoice-header h4 {
      font-size: 70%;
  }

  #document-list {
    width: 100%;
  }

  #document-fieldset h4 {
    font-size: 70%;
  }

  #timelist-minimized {
    width: 5rem;
    left:12rem;
    font-size: x-small;
  }
  #faktura-minimized {
    width: 5rem;
    left: 17rem;
    font-size: x-small;
  }
  #offer-minimized {
    width: 5rem;
    left: 22rem;
    font-size: x-small;
  }

  #top-beam h1 {
    margin-left: 1.2rem;
    font-size: 120%;
    font-variant: small-caps;
  }
  
  #timelist-minimized p, #faktura-minimized p, #offer-minimized p {
    width: 50%;
    text-align: center;
  }

  }