.client-background {
    background-color: rgba(0,0,0,0.9);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 8;
}

.client-window {
    background: radial-gradient(circle at 24.1% 68.8%, rgb(255, 255, 255) 0%, rgb(50, 50, 50) 99.4%);
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    min-height: 40%;
    max-height: 90%;
    transform: translate(0, 0%);
    z-index: 9;
    overflow: auto;
}


#client-ico-set {
    display: inline-block;
    width: fit-content;
}
  


#client-fulllist{
    background-color: #FFF;
    margin: 5rem 5%;
    width: 90%;
    height: 70%;
}
 #client-fulllist-item {
    border-bottom: #000 solid 1px;
    width: 100%;
    height: 3rem;
    cursor: pointer;
}
#client-fulllist-item {
    display: grid;
    grid-template-columns: 80% 20%;
    column-gap: 0;
    position: relative;
}

#client-fulllist-item:hover {
    border-bottom: #000 solid 1px;
    background-color: #000;
    color: #FFF;
}

.client-card {
    padding-top: 3rem;
    color: #FFF;
    background-color: rgba(9,9,9,0.9);
    display: grid;
    grid-template-columns: 30% 70%;
}

.client-details {
    width: 90%;
    min-height: 9rem;
    max-height: fit-content;
    margin: 0 5%;
    padding: 1rem;
    color: #FFF;
}


.client-section-header {
    border-bottom: #FFF 1px solid;
}


@media only screen and (max-width: 600px) {
    .client-card {
        background-color: rgba(9,9,9,0.9);
        padding-top: 3rem;
        color: #FFF;
        display: block;
    }
}