
.login-panel {
    top: 0;
    left: 40%;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20%;
    height: fit-content;
    background-color: rgba(221, 221, 221, 0.96);
    border-radius: 15px;
    padding: 2rem;
    font-variant: small-caps;

}

.login-panel div {
    margin: 0.5rem 0;
}

.front-login-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 2rem;
    padding-top: 10%;
    width: 80%;
    height: 70%;
    background-image: url('/public/jobb.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #EEE;
    border-radius: 15px;
    font-variant: small-caps;
    font-size: 2rem;
}


@keyframes slideshow{
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    4% {
        display: block;
        opacity: 1;
    }
    9% {                     /* = (fadingin + showing + fadingout) / duration * 100% */
        display: block;
        opacity: 0;
    }
    10% {
        display: none;
    }
   100% {
        display: none;
    }
  }
.front-login-page p {

    width: 70%;
    animation-name: slideshow;
    animation-duration: 50s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}
.front-login-page span {
    font-size: 2rem;
    font-weight: 700;
}

.front-login-page p:nth-of-type(1)
{
    animation-delay:5.1s;
    opacity: 0;
}
.front-login-page p:nth-of-type(2)
{
    animation-delay:10.2s;
    opacity: 0;
}
.front-login-page p:nth-of-type(3)
{
    animation-delay:15.3s;
    opacity: 0;
}
.front-login-page p:nth-of-type(4)
{
    animation-delay:20.4s;
    opacity: 0;
}
.front-login-page p:nth-of-type(5)
{
    animation-delay:25.5s;
    opacity: 0;
}
.front-login-page p:nth-of-type(6)
{
    animation-delay:30.6s;
    opacity: 0;
}
.front-login-page p:nth-of-type(7)
{
    animation-delay:35.7s;
    opacity: 0;
}
.front-login-page p:nth-of-type(8)
{
    animation-delay:40.8s;
    opacity: 0;
}
.front-login-page p:nth-of-type(9)
{
    animation-delay:45.9s;
    opacity: 0;
}
.front-login-page p:nth-of-type(10)
{
    animation-delay:51s;
    opacity: 0;
}

@media only screen and (max-width: 800px) {
    .login-panel {
        left: 0;
        width: 80%;
        height: fit-content;    
    }
    .front-login-page p {
        width: 60%;
    }
    .front-login-page {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 80%;
        height: 70%;
        background-image: url('/public/jobb.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #DDD;
        border-radius: 15px;
        padding: 2rem;
        font-size: small;
        font-variant: small-caps;
    }
    .front-login-page span {
        font-size: small;
        font-weight: 700;
    }
}