p span {
  margin-left: 0.4rem;
  font-size: xx-small;
  cursor: pointer;
}



nav {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 3.7rem;
  z-index: 4;
  background-image: url('/public/jobbleder15.svg');
  background-repeat: no-repeat;
  background-position-x: 0.2rem;
  background-size: contain;
  border-bottom: #000 0px solid;
  background-color: rgb(50, 50, 50);

}

.darkmode {
  background-color: #000;
  color: #DDD;
}

.lightmode {
  background-color: #DDD;
  color: #000;
}

div .container-fluid {
  padding: 0;
}

.nav-tabs {
  background-color: white;
}

#minimize-button {
  position: absolute;
  top: 1vh;
  right: 5rem;
}

#close-button {
  position: absolute;
  top: 1vh;
  right: 1rem;
}

#help-button {
  position: absolute;
  text-align: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid;
  border-radius: 100%;
  font-weight: 600;
  top: 1vh;
  right: 7rem;
  font-size: larger;

}

#creator-button {
  position: absolute;
  top: 1vh;
  right: 2.5rem;
  width: 2rem;
  height: 2rem;
  background-image: url('/public/creator.svg');
  background-size: 110%;
}

#page-button {
  position: absolute;
  top: 1vh;
  right: 2.5rem;
  width: 2rem;
  height: 2rem;
  background-image: url('/public/page.svg');
  background-size: 110%;
}

.system-button {
  cursor: pointer;
  font-size: 150%;
  font-weight: 600;
}

.system-button:hover {
  cursor: pointer;
  transform: scale(1.1);
  font-weight: 800;

}


.selected {
  font-weight: 800;
}


main {
  background-color: #111;
  padding: 0;
  margin-top: 0px;
  margin-bottom: 50px;
}

.clickable-panel {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
}


@media only screen and (max-width: 600px) {
  .clickable-panel {

  }

}