 @keyframes fadeIn {
  0% {opacity: 0}
  3% {opacity: 1}
  97% {opacity: 1}
  100% {opacity: 0}
}
  .jobbie-window{
    background-color: #ddd;
    position: fixed;
    top: 85%;
    left: 80%;
    width: 19%;
    min-height: 20%;
    max-height: fit-content;
    transform: translate(0, -50%);
    padding: 100px;
    z-index: 991;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 15px;
    opacity: 0;
    animation: fadeIn 6s 0.3s forwards ease-in-out;
  }

  
.jobbie-close-button{
    position: fixed;
    transform: scale(2.0);
    top: 1rem;
    right: 3vh;
    cursor: pointer;
    color: #AAA;
  }
  
  .jobbie-topbeam{
    position: absolute;
    background-color:#000;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 15px;

  }
  .jobbie-topbeam h1 {
    font-size: 120%; 
    width: 100%;
    margin: auto auto;
    color: #AAA;
    text-align: center;
    font-variant: small-caps;
  }
  .jobbie-ico {
    background-color: black;
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 5rem;
    left: 2rem;
  }

  .jobbie-info {
    width: 90%;
    margin: 0 5%;
    color: #000;

  }
  
  .jobbie-confirm p , .jobbie-info p, .jobbie-error p, .jobbie-warning p {
   text-align: center;
   font-size: larger;
   color: #000;
  }
  
  .jobbie-new-client {
    color: #FFF;
  }
  
  .jobbie-new-project, .jobbie-new-task{
    position: absolute;
    top: 50%;
    left: 30%;
    width: 80%;
  }
  
  .jobbie-settings {
    width: 95%;
  }
  .jobbie-new-client-buttons
  {
    margin: 0 30%;
    width: 40%;
  }
  
  .jobbie-buttons  {
    position: absolute;
    bottom: 0;
    left: 35%;
  }

  
  @media only screen and (max-width: 600px) {
    .jobbie-window{
      top: 5%;
      left: 5%;
      width: 90%;
      transform: translate(0, -5%);
      padding: 7rem 0 7rem 7rem;
    }

    .jobbie-ico {
      background-color: black;
      width: 5rem;
      height: 5rem;
    }
  }