.stats-knob-panel {
    width: 100%;
    height: 100%;
    display: block;
}

.stats-knob {
    margin: 1rem 5%;
    padding: 1rem;
    width: 90%;
    height: 8rem;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 5%;
    position: relative;
    border: #010 1px solid;
    cursor: pointer;
    color: #DDD;
    display: inline-block;
}



.stats-knob-selected {
    margin: 1rem 5%;
    padding: 1rem;
    width: 90%;
    height: 8rem;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 5%;
    position: relative;
    border: #010 1px solid;
    cursor: pointer;
    color: #DDD;
    display: inline-block;
}


.stats-knob-value p{
    font-size: 2rem;
}
.stats-knob-value {
    height: fit-content;
    text-align: center;
    color: #FFF;
}


.stats-knob .clickable-panel:hover{
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 50%;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    border: #EEE 1px solid;
}


.stats-knob-option {
    height: 3rem;
    text-align: center;
    font-variant: small-caps;
}
.stats-knob-option p{
    text-align: center;
    font-style: italic;
    font-variant: small-caps;
    font-size: 1rem;
}


.stats-knob-timelist{
    padding: 2rem;
    background-image: url('/public/timelist.svg');
    border: 3px solid rgb(255, 127, 39);
    color: #000;
    background-position: 50% 50%;
    background-size: 50%;
}



@media only screen and (max-width: 600px) {

    .stats-knob-panel   {
        width: 100%;
    }

    .stats-knob, .stats-knob-selected{

        height: 6rem;

    }
        
    .stats-knob-value p{
        display: inline-block;
        font-size: 1.2rem;
    }
    .stats-knob-option p{
        display: inline-block;
        font-size: 0.8rem;
    }
    

  }