#new-timelist-steps div{
    width: 4rem;
    height: 4rem;
    border: 2px solid #000;
    display: inline;
    margin: auto auto;
    border-radius: 100px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}

#new-timelist-steps div p {
    background-color: #000;
    width: 50%;
}

#new-timelist-steps div:nth-of-type(1) {
    background-image: url('/public/clients.svg');
}

#new-timelist-steps div:nth-of-type(2) {
    background-image: url('/public/projects.svg');
}

#new-timelist-steps div:nth-of-type(3) {
    background-image: url('/public/client.svg');
}

#new-timelist-steps div:nth-of-type(4) {
    background-image: url('/public/documents.svg');
}

#new-timelist-steps div:nth-of-type(5) {
    background-image: url('/public/preview.svg');
}


#new-invoice-steps div:nth-of-type(1) {
    background-image: url('/public/clients.svg');
}

#new-invoice-steps div:nth-of-type(2) {
    background-image: url('/public/projects.svg');
}

#new-invoice-steps div:nth-of-type(3) {
    background-image: url('/public/calendar.svg');
}

#new-invoice-steps div:nth-of-type(4) {
    background-image: url('/public/documents.svg');
}
#new-invoice-steps div:nth-of-type(5) {
    background-image: url('/public/preview.svg');
}


#new-timelist-steps p {
    text-align: center;
}

#new-timelist-steps {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin: 2rem 0;
}

.step-done {
    background-color: #000;
    color: #FFF;
    opacity: 1;
}

.step-waiting {
    background-color: #000;
    color: #FFF;
    opacity: 0.2;
}

#new-invoice-steps div{
    width: 4rem;
    height: 4rem;
    border: 2px solid #000;
    display: inline;
    margin: auto auto;
    border-radius: 100px;
    cursor: pointer;

}
#new-invoice-steps p {
    text-align: center;
}

#new-invoice-steps {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin: 2rem 0;
}


