
.popup-background{
  background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 990;
  }
  
  .popup-window{
    background: radial-gradient(circle at 24.1% 68.8%, rgb(255, 255, 255) 0%, rgb(50, 50, 50) 99.4%);
    position: relative;
    margin: 5% auto;
    width: 50%;
    min-height: 50%;
    max-height: 90%;
    z-index: 991;
    border-top-left-radius: 15px;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    overflow: auto;
  }

  
.popup-close-button{
    position: absolute;
    transform: scale(2.0);
    top: 1rem;
    right: 3vh;
    cursor: pointer;
    color: #AAA;
  }
  
  .popup-topbeam{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color:#000;
    width: 100%;
    height: 3rem;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: contain;
    border-top-left-radius: 15px;
  }
  .popup-topbeam h1 {
    font-size: 120%;
    margin-left: 3rem;
    color: #FFF;
    font-variant: small-caps;
  }
  .popup-ico {
    background-color: black;
    width: 20vh;
    height: 20vh;
    position: absolute;
    top: 13vh;
    left: 3vh;
  }

  .popup-confirm, .popup-info, .popup-error, .popup-warning {
    border-top-left-radius: 15px;
    position: relative;
    width: 100%;
    height: auto;
    margin: 10% auto;
  }
  
  .popup-new-user {
    padding: 0.3rem;
  }

  .popup-confirm div , .popup-info div, .popup-error div, .popup-warning div  {
    position: relative;
    width: 80%;
    height: fit-content;
    margin: auto auto;

  }

  .popup-confirm p , .popup-info p, .popup-error p, .popup-warning p {
    position: relative;
    width: 100%;
    height: fit-content;
    margin: auto auto;
    text-align: center;
   font-size: 2rem;
   color: #000;

  }
  
  .popup-new-client {
    color: #FFF;
  }
  
  .popup-new-project, .popup-new-task{
    position: absolute;
    top: 50%;
    left: 20%;
    width: 80%;
  }
  
  .popup-settings {
    width: 95%;
  }
  .popup-new-client-buttons
  {
    margin: 0 auto;
    width: fit-content;
  }
  
  .popup-buttons  {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    width: fit-content;
    margin: 0 auto;
  }

  .popup-time-component {
    width: 50%;
    margin: 2rem auto;
  }

  .popup-ad {
    position: relative;
    background-color: #FFF;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    z-index: 99;
    text-align: center;
  }


  
  @media only screen and (max-width: 1020px) {
    .popup-window{
      background-color: #DDD;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      position: relative;
      margin: 5% auto;
      width: 90%;
      min-height: 50%;
      height: fit-content;
      z-index: 991;
      box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
      border-radius: 15px;
      overflow: auto;
    }

    .popup-buttons  {
      position: relative;
      bottom: 0;
      left: 0;
      right: 0;
      width: fit-content;
      margin: 0 auto;
    }


    .popup-ico {
      background-color: black;
      width: 5rem;
      height: 5rem;
    }
  }

