.project-background {
    background-color: rgba(0,0,0,0.9);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 8;
}

.project-window {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    min-height: 90%;
    max-height: 90%;
    transform: translate(0, 0%);
    z-index: 9;
    overflow: auto;
    background: radial-gradient(circle at 24.1% 68.8%, rgb(255, 255, 255) 0%, rgb(50, 50, 50) 99.4%);
    border-top-left-radius: 15px;
}

#project-ico-set {
    display: inline-block;
    width: fit-content;
}  

#project-fulllist{
    background-color: #FFF;
    margin: 5rem 5%;
    width: 90%;
    height: 70%;
}
 #project-fulllist-item {
    border-bottom: #000 solid 1px;
    width: 100%;
    height: 3rem;
    cursor: pointer;
}
#project-fulllist-item {
    display: grid;
    grid-template-columns: 80% 20%;
    column-gap: 0;
    position: relative;
}

#project-fulllist-item:hover {
    border-bottom: #000 solid 1px;
    background-color: #000;
    color: #FFF;
}

.project-card {
    background-color: rgba(9,9,9,0.9);
    padding-top: 3rem;
    height: auto;
    color: #FFF;
    display: grid;
    grid-template-columns: 30% 70%;
}

.project-details {
    
    width: 90%;
    min-height: 9rem;
    max-height: fit-content;
    margin: 0 5%;
    padding: 1rem;
}

.project-section-no-details{
    cursor: pointer;
    height: 100%;

}

.project-section-no-details p{
    font-size: 110%;
}

.project-section-header {
    border-bottom: #AAA 1px solid;
}

.project-section {
    min-height: 7rem;
    margin: 2rem 0;
}
.project-menu-wrapper {
    width: 100%;
    height: 25%;
    display: grid;
    grid-template-columns: 33% 33% 33%;

}

.project-menu-item {
    margin-top: 3rem;
    height: 68%;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 5%;
    position: relative;
    background-color: #000;
    border: #000 1px solid;
    cursor: pointer;
    color: #AAA;
    font-variant: small-caps;
}
.project-menu-item-selected {
    margin-top: 3rem;
    height: 78%;
    position: relative;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    border: #EEE 1px solid;
    cursor: pointer;
    color: #FFF;
    background-color: #000;
    font-variant: small-caps;
}

.project-menu-item:hover{
    font-weight: 800;
}
.project-menu-item p, .project-menu-item-selected p{
    width: 100%;
    position: absolute;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .project-card {
        background-color: rgba(9,9,9,0.9);
        padding-top: 3rem;
        color: #FFF;
        display: block;
    }
}