.edit-setting-window {
    background: radial-gradient(circle at 24.1% 68.8%, rgb(255, 255, 255) 0%, rgb(50, 50, 50) 99.4%);
    position: absolute;
    top: 20%;
    left: 25%;
    width: 50%;
    min-height: 20%;
    max-height: 90%;
    z-index: 15;
    overflow: auto;
    transform: translate(0, 0%);
    border-top-left-radius: 15px;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px 3px 8px rgba(90, 90, 90, 0.6);
}

.edit-setting-window-box {
    padding: 2rem 1rem;
}


.edit-setting-background {
    background-color: rgba(9,9,9,0.9);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 100;
}


.settings-background {
    background-color: rgba(0,0,0,0.8);
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 8;
    }

.settings-window {
    background: radial-gradient(circle at 24.1% 68.8%, rgb(255, 255, 255) 0%, rgb(50, 50, 50) 99.4%);
    position: absolute;
    top: 10%;
    left: 25%;
    width: 50%;
    min-height: 40%;
    max-height: 90%;
    z-index: 9;
    overflow: auto;
    transform: translate(0, 0%);
    border: #999 1px solid;
    border-top-left-radius: 15px;
}

.edit-setting-window h4 {
    color: #FFF;
    font-size: large;
}

.settings-document-settings h3 {
    color: #000;
    font-size: large;
    font-variant: small-caps;
    border-bottom: #FFF 1px solid;
    width:30%;
    margin: 1rem 0 4rem 0;
}

.settings-menu-wrapper {
    width: 100%;
    height: 25%;
    display: grid;
    grid-template-columns: 50% 50%;

}

.knob-panel {
    text-align: center;
    width: 100%;
    height: 100%;

}

.setting-knob {
    margin: 1rem 5%;
    padding: 1rem;
    width: 90%;
    height: 8rem;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 5%;
    position: relative;
    border: #010 1px solid;
    cursor: pointer;
    color: #DDD;
    background-color: #000;
    display: inline-block;
}

.setting-knob-selected {
    margin: 3rem;
    padding: 1rem;
    width: 90%;
    height: 8rem;
    position: relative;
    background-color: #000;
    border: #010 1px solid;
    cursor: pointer;
    color: #DDD;
    display: inline-block;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    border: #EEE 1px solid;
}


.setting-knob-value p{
    font-size: 2rem;
}
.setting-knob-value {
    height: fit-content;
    text-align: center;
    color: #FFF;
}

.setting-knob .clickable-panel:hover{
    background-image: url('/public/edit.svg');
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 10%;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    border: #EEE 1px solid;
}


.setting-knob-option {
    height: auto;
    text-align: center;
    font-variant: small-caps;
}

.setting-knob-option p{
    text-align: center;
    font-style: italic;
    font-variant: small-caps;
    font-size: 1rem;
}

.settings-menu-item {
    margin-top: 3rem;
    height: 68%;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 5%;
    position: relative;
    background-color: #000;
    border: #000 1px solid;
    cursor: pointer;
    color: #AAA;
    font-variant: small-caps;
}
.settings-menu-item-selected {
    margin-top: 3rem;
    height: 78%;
    position: relative;
    background-color: #000;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    border: #EEE 1px solid;
    cursor: pointer;
    color: #FFF;
    font-variant: small-caps;
}

.settings-menu-item:hover{
    font-weight: 800;
}
.settings-menu-item p, .settings-menu-item-selected p{
    width: 100%;
    position: absolute;
    text-align: center;
}


.jobbleder-settings{
    margin-top: 5rem;
}

.settings-document-settings {
    margin: 0 1% 10% 2%; 
}

.settings-document-settings div{
    background-repeat: no-repeat;
    background-position: 95% 5%;
    background-size: 5%;

}

.settings-document-settings div p{
    border-bottom: #000 1px solid;
}

.tilbud-settings{
    padding: 2rem;
    background-image: url('/public/tilbud.svg');
    
    color:#000;
}
.faktura-settings{
    padding: 2rem;
    background-image: url('/public/faktura.svg');

    
    color: #000;
}
.timelist-settings{
    padding: 2rem;
    background-image: url('/public/timelist.svg');
    
    color: #000;
}

.setting {
    padding: 0;
    margin: 0;
}

.setting div{
    display: inline;
    margin: 0;
    padding: 0 0.3rem;
    border-left: 1px #000 solid;
}


.setting-wizard{
    background: radial-gradient(circle at 24.1% 68.8%, rgb(255, 255, 255) 0%, rgb(50, 50, 50) 99.4%);
    position: relative;
    margin: 5% auto;
    width: 50%;
    min-height: 50%;
    max-height: 90%;
    z-index: 9;
    border-top-left-radius: 15px;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    overflow: auto;
  }

  
.setting-wizard-close-button{
    position: absolute;
    transform: scale(2.0);
    top: 1rem;
    right: 3vh;
    cursor: pointer;
    color: #AAA;
  }
  
  .setting-wizard-topbeam{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color:#000;
    width: 100%;
    height: 3rem;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: contain;
    border-top-left-radius: 15px;
  }
  .setting-wizard-topbeam h1 {
    font-size: 120%;
    margin-left: 3rem;
    color: #FFF;
    font-variant: small-caps;
  }
  .setting-wizard-ico {
    background-color: black;
    width: 20vh;
    height: 20vh;
    position: absolute;
    top: 13vh;
    left: 3vh;
  }

@media only screen and (max-width: 600px) {


.setting-knob, .setting-knob-selected{
    width: 80%;
    height: 6rem;
    margin: 3rem 0;
}
.setting-knob .clickable-panel, .setting-knob .clickable-panel:hover{
    background-image: url('/public/edit.svg');
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 20%;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 10px;
    border: #EEE 1px solid;
}
    
.setting-knob-value p{
    display: inline-block;
    font-size: 1.2rem;
}
.setting-knob-option p{
    display: inline-block;
    font-size: 0.8rem;
}

    .settings-window{
      position: absolute;
      top: 1%;
      left: 2%;
      width: 95%;
    }
    .edit-setting-window {
        background-color: #000;
        position: absolute;
        top: 20%;
        left: 5%;
        width: 90%;
        min-height: 20%;
        max-height: 90%;
        z-index: 15;
        overflow: auto;
        transform: translate(0, 0%);
        box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    }
  }