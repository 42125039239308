.cookies-popup{
	position: fixed;
	bottom: 0;
	right: 18px;
	left: 18px;
	width: 100vw-36px;
	height: 100px;
	padding: 20px;
	background-color: #000;
	z-index: 11;
	opacity: 0.7;
	text-align: center;
	border: #999 2px solid;
	animation-name: popup;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}

.cookies-popup-disappear{
	position: fixed;
	bottom: 0;
	right: 18px;
	left: 18px;
	width: 100vw-36px;
	height: 100px;
	padding: 20px;
	background-color: #000;
	z-index: 11;
	opacity: 0;
	text-align: center;
	border: #999 2px solid;
	animation-name: hide;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}

.cookies-popup p{
	color: #FFF;
	font-size: 24px;
}


.social-popup{
	position: fixed;
	top: 50%;
	right: 0px;
	width: 250px;
	height: 250px;
	padding: 20px;
	background-color: #000;
	z-index: 11;
	opacity: 0.7;
	text-align: left;
	border: #999 2px solid;
	animation-name: popup;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}
.social-popup-close-button{
	position: absolute;
	cursor: pointer;
	top: 5px;
	right: 10px;
	font-size: 30px;
	font-weight: 500;
	text-shadow: #FFF 2px 2px 2px;
}
.social-popup a img:not(.google-ico img){
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(1) invert(1);
}
.google-ico img{
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(0) invert(1);
}
.social-logotype{
	width: 180px;
	margin: 10px 20px 30px 15px;
	filter: brightness(0) invert(1);
}
.social-popup p{
	font-size: 11px;
	color: #FFF;
}
.social-popup a{
	color: #FFF;
	font-size: 11px;
	text-decoration: none;
}
.social-popup a:hover{
	text-decoration: none;
	transform: scale(2.0);
	color: #FFF;
}

.social-popup a:active{
	text-decoration: none;
	transform: scale(0.2);
	color: #FFF;
}

@keyframes popup {
  from {
  	opacity: 0;
  	display: none;
  }
  to {
  	opacity: 0.7;
  	display: initial;
  }
}

@keyframes hide-animation {
  from {
  	opacity: 1;
  }
  to {
  	display: none;
  	opacity: 0;
  }
}
