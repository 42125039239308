#timeline-canva {
    width: 100%;
    height: 30rem;
    padding: 2%;
    color: #FFF;
  
}

#timeline-box {
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 5px; 
    padding-bottom: 40px;
    background-color: #DDD;
}

#timeline-week{
    width: 96%;
    height: 90%;
    background-color: #DDD;
    position: absolute;
    top: 8%;
    left: 2%;
    border-bottom:  #FFF solid 10px;
    z-index: 1;
}

#timeline-container {
    width: 100%;
    padding: 0;
}


#timeline-day{
    position: absolute;
    bottom: 0;
    height: 100%;
}

#timeline-day p {
    transform: rotate(-90deg);
    position: relative;
    top: 4rem;
    cursor: default;
}

#timeline-day:hover, .tl-row-mobile:hover{
    font-weight: 700;
    border-right: #FFF solid 1px;
    cursor: pointer;
    background-color: #fff;
}
#timeline-day-mobile-header{
    position: sticky;
    top: 3.7rem;
    background-color: #DDD;
    z-index: 4;
    font-variant: small-caps;
}

#timeline-day-mobile p {
    display: inline-block;
    padding-left: 12%;
    position: relative;
}

#timeline-empty-day {
    height: 0.1rem;
    border-left: #000 solid 1px;
}
#week-no{
    height: 13%;
    width: 100%;
    position: absolute;
    bottom: 0;    
    background-color: #000;

    color: #FFF;
}

#timeline-left-arrow, #timeline-right-arrow {
    margin: 2% 0;
    z-index: 0;
    cursor: pointer;

}

#timeline-left-arrow:hover, #timeline-top-arrow:hover{
    border-right-color:  solid rgba(30,30,30,0.1);
}

#timeline-right-arrow:hover, #timeline-bottom-arrow:hover {
    border-left-color:  solid rgba(30,30,30,0.5);

}



#timeline-top-arrow {
    position: fixed;
    right: 2rem;
    bottom: 12%;
    border-top: 25px solid transparent;
    border-right: 50px solid #000;
    border-bottom: 25px solid transparent;
    transform: rotate(90deg);
    z-index: 2;
    cursor: pointer;
}
#timeline-bottom-arrow {
    cursor: pointer;
    display: inline-block;
    margin: 1rem 3%;
    border-top: 25px solid transparent;
    border-left: 50px solid #000;
    border-bottom: 25px solid transparent;
    transform: rotate(90deg);
    z-index: 20;
}

#timeline-filters {
    border: #000 solid 2px;
    border-radius: 15px;
    background-color: #000;
    cursor: pointer;
    position: fixed;
    right: 1%;
    top: 10%;
    background-image: url('/public/filters.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 5rem;
    height: 5rem;
    z-index: 2;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
}

#timeline-left-arrow {
    position: absolute;
    left: 0%;
    top: 33%;
    border-top: 50px solid transparent;
    border-right: 100px solid #FFF;
    border-bottom: 50px solid transparent;
}

#timeline-right-arrow {
    position: absolute;
    left: 95%;
    top: 33%;
    border-top: 50px solid transparent;
    border-left: 100px solid #FFF;
    border-bottom: 50px solid transparent;
}

#timeline-documents {
    margin-top: 6rem;
    width: 90%;
}


.timeline-documents-point {
    width: 100px;
    height: 100px;
    display: inline;
    padding: 0% 0% ;
    
}

.timeline-documents-list {
    width: 100%;
    border-left: #000 solid 1px;
    height: 100px;
    padding: 5% 0% ;
    
}

#timeline-axis {
    background-color: #000;
    width: 90%;
    height: 1px;
}

#timeline-scale {
    width: 90%;
    height: 1rem;
}

.timeline-scale-point {
    width: 100px;
    height: 10rem;
    border-left: #000 solid 1px;
    display: inline;
    padding: 0 1%;
}

.tl-ico {
    background-image: url('/public/timelist.svg');
    width: 4rem;
    height: 4rem; 
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    background-position-y: 90%;
    }

#faktura-header-preview {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #403DF5;
    font-weight: 700;
    color: #FFF;
}
#tilbud-header-preview {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #3DF586;
    font-weight: 700;
    color: #000;
}

#timelist-header-preview  {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #F5D53D;
    font-weight: 700;
    color: #000;
}

#kreditnota-header-preview {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #5E5DA0;
    font-weight: 700;
    color: #fff;
}

.client-card-link {
    cursor: pointer;
}

#document-preview {
    max-width: 100%;
    min-width: 80%;
    height: 80vh;
    background-color: #FFF;
    color: #000;
    border: #000 solid 1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 9;
    font-size: 80%;
    cursor: zoom-in;
    overflow: hidden;
}

#stapled-documents {
    height: fit-content;
    width: 100%;
    position: absolute;
    top: 1.2rem;
    left: 0;
    right: 0;
}

.stapled-document-header{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: fit-content;
    width: fit-content;


}

#timelist-content-preview {
    border: 1px solid;
    text-align: center;
    width: 100%;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 10% 30% 10% 10% 10% 30%;
}

#timelist-content-preview div {
    border-left: #000 dotted 1px;
    border-right: #000 dotted 1px;
    padding: 2rem;
    height: 100%;
}


#timelist-content-mini-preview {
    border: 1px solid;
    text-align: center;
    width: 96%;
    margin: 0 2%;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 12% 22% 12% 12% 42%;
}
#timelist-content-with-km-preview {
    border: 1px solid;
    text-align: center;
    width: 96%;
    margin: 0 2%;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 10% 20% 10% 10% 10% 40%;
}

#timelist-content-mini-preview div, #timelist-content-with-km-preview div {
    border-left: #000 dotted 1px;
    border-right: #000 dotted 1px;
    padding: 0.5rem 0;
    height: 100%;
}

#timelist-content-preview-header{
    border: 1px solid;
    height: 100%;
    width: 96%;
    margin: 0 2%;
    border: 1px solid;
    text-align: center;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 10% 20% 10% 10% 10% 40%;
    font-variant: small-caps;
}


#timelist-content-preview-header div
{
    height: 100%;
    width: 100%;
    border: #000 solid 1px;
    padding: 2rem; 
}

#timelist-content-mini-preview-header{
    border: 1px solid;
    height: 100%;
    width: 96%;
    border: 1px solid;
    text-align: center;
    margin: 0 2%;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 12% 22% 12% 12% 42%;
    font-variant: small-caps;
    font-size: small;
    margin-top: 1rem;
}

#timelist-content-with-km-preview-header{
    border: 1px solid;
    height: 100%;
    width: 96%;
    border: 1px solid;
    text-align: center;
    margin: 0 2%;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 10% 20% 10% 10% 10% 40%;
    font-variant: small-caps;
    font-size: small;
    margin-top: 1rem;
}


#timelist-content-mini-preview-header div, #timelist-content-with-km-preview-header div
{
    height: 100%;
    width: 100%;
    border: #000 solid 1px;
    padding: 0.5rem 0;
    
}


#timelist-sumup {
    text-align: center;
    border: #000 1px solid;
    padding: 2%;
    margin: 1% auto;
    width: 20%;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 50% 50%;
    font-weight: 800;
    font-size: 100%;
}

#timelist-sumup-mini {
    text-align: right;
    padding: 2%;
    margin: 1% auto;
    width: 90%;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 70% 30%;
    font-weight: 600;
    font-size: 90%;
}


#faktura-content-preview, #tilbud-content-preview {
    border: #000 1px solid;
    text-align: center;
    margin: 0 2%;
    padding: 1em;
    width: 96%;
    display: grid;
    column-gap: 0%;
    grid-template-columns: 5% 25% 17.5% 17.5% 17.5% 17.5%;
}
#faktura-data-preview, #tilbud-data-preview {
    text-align: left;
    padding: 2%;
    width: 100%;
}

#faktura-basic-data {
    width: 94%;
    margin: 1% auto;
    display: grid;
    grid-template-columns: 50% 50%;
    font-size:medium;
}

#faktura-dates-preview {
    text-align: right;
    padding: 5%;
    width: 100%;
    font-variant: small-caps;
}

#timelist-data-preview {
    width: 100%;
    display: grid;
    grid-template-columns: 20% 40% 20% 20%;
    font-weight: 600;
    font-variant: small-caps;
    text-align: center;
}
#timelist-data-preview div {
    border: #000 1px solid;
    padding: 1rem;
}

#faktura-data-preview div:first-of-type, #tilbud-data-preview div:first-of-type{
    margin-top: 1rem;
    font-weight: 600;
    font-variant: small-caps;
}

.document-options{
    display: grid;
    column-gap: 0%;
    grid-template-columns: 25% 25% 25% 25%;
    align-content: center;
    border: 1px #000 solid;
}

.show-document {
    animation: visibility-toggle 3s 0s forwards;
}
.hide-document {
    animation: visibility-toggle 10s 1s backwards;
}

@keyframes visibility-toggle {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    50% {
        display: block;
        opacity: 1;
    }
    100% {
        display: block;
        opacity: 1;
    }
  }


#timeline-date{
    text-align: center;
    vertical-align: top;
    height: 2rem;
    width: 10%;
    display: inline-block;
}

    #timeline-day-mobile {
        min-height: 4rem;
    }
    #timeline-documents-mobile {
        display: inline-block;
        width: 85%;
    }
    #timeline-documents-mobile-header{
        display: inline-block;
        text-align: center;
        width: 80%;
    }
    
    #timeline-canva-mobile {
        width: 50%;
        height: fit-content;
        padding: 2%;
        position: absolute;
        right: 0;
        z-index: 1;
    }


    #timeline-doc-nr {
        background-color: rgba(255,255,255,1);
        color: #000;
        width: 3.5rem;
        height: fit-content;
        font-size: xx-small;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        font-weight: 600;
        position: absolute;
        top: 80%;
        bottom: 0;
    }

    
    #timeline-doc-nr-row {
        color: #000;
        width: fit-content;
        height: fit-content;
        font-size: large;
        margin: 0 0;
        padding: 0;
        text-align: center;
        font-variant: small-caps;
        font-weight: 600;
    }

    #timeline-doc-nr span {
        font-weight: 800;
        margin: 0;
        padding: 0; 
    }

    #timeline-doc-nr-row span {
        font-weight: 800;
        margin: 0;
        padding: 0;
        font-size: large;   
    }

    #timeline-sidebar {
        width: 50%; 
        height: 90%;
        max-height: max-content;
        padding: 2%;
        position: fixed;
        left: 5rem;
        bottom: 0.2rem;
        z-index: 1;
    }

    #timeline-sidebar-box {
        position: relative;
        box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
        border-radius: 5px; 
        padding: 1%;
        background-image: url('/public/jobbleder.svg');
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 40%;
        width: 90%;
        min-height: 96%;
        margin: 0 0;
    }


    #week-no-mobile{
        height: fit-content;
        width: 5%;
        color: #000;
        display: inline-block;
        z-index: 1;
        text-align: center;
        vertical-align: top;

    }

    .tl-ico-new-mobile {
        width: 2.7rem;
        height: 2.7rem; 
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-y: center;
        background-position-x: center;
        display: inline-block;
        background-image: url('/public/time_plus.svg');
    }

    .tl-ico-mobile {
        width: 2.7rem;
        height: 2.7rem; 
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 105%;
        position: relative;
        background-position-y: 90%;
        background-position-x: center;
        margin: 1rem;
        display: inline-block;
        border: #000 2px solid;
        box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    }

    .tl-ico-row {
        width: 100%;
        height: 4rem; 
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        background-position-y: 50%;
        background-position-x: 0%;
        display: block;
    }
    
    .tl-row-mobile {
        height:4rem; 
        cursor: pointer;
        background-repeat: no-repeat;
        position: relative;
        background-position-y: 90%;
        background-position-x: center;
        margin: 1rem;
        display: block;
        box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    }

    #week-number {
        z-index: 1;
        font-size: xx-small;
        text-align: center;
        vertical-align: top;
        border-radius: 100%;
        border: #000 1px solid;
        opacity: 0;
        width: 1rem;
        height: 1rem;
        display: inline-block;
    }

    #week-number-sunday {
        z-index: 1;
        font-size: xx-small;
        text-align: center;
        vertical-align: top;
        border-radius: 100%;
        width: 1.5rem;
        height: 1rem;
        border: #000 1px solid;
        opacity: 1;
    }
    
    #timeline-week-header{
        text-align: center;
        vertical-align: top;
        height: 2rem;
        width: fit-content;
        display: inline-block;

    }

    #timeline-date-header{
        text-align: center;
        vertical-align: top;
        height: 2rem;
        width: 10%;
        display: inline-block;
    }
        
#timeline-week-mobile{
    width: 96%;
    height: 99%;
    background-color: #DDD;
    z-index: 1;
    margin: 1rem auto;
}

#documents-view-options-beam{
    position: fixed;
      width: 10%;
      height: 5rem;
      margin:  0 1rem;
      top: 10%;
      right: 1%;
      display: block;
      z-index: 5;
  }

  @media only screen and (min-device-width: 1280px) and (max-device-width: 1599px) {
    #timeline-sidebar {
        min-width: 50%; 
        height: 90%;
        max-height: max-content;
        padding: 2% 5% 2% 5%;
        position: fixed;
        left: 3rem;
        bottom: 0.2rem;
        z-index: 1;
        }
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1279px) {
   #timeline-sidebar {
    min-width: 50%; 
    height: 90%;
    max-height: max-content;
    padding: 2%;
    position: fixed;
    left: 3rem;
    bottom: 0.2rem;
    z-index: 1;
    }
}
@media only screen and (max-width: 1024px) {
    #timeline-sidebar {
        width: 50%;
        left: 0%;
    }
    #timeline-day-mobile-header{
        top: 60px;
        }
    #timeline-documents-mobile {
        vertical-align: top;
        display: inline-block;
        width: 100%;
    }
    #timeline-documents-mobile-header{
        display: none;
    }
    #timeline-date-header {
        width: 60%;
    }
    
    #timeline-week-header {
        width: 10%;
    }
    #timeline-date {
        text-align: left;
        width: 70%;
    }

    #documents-view-options-beam{
        position: fixed;
        width: 40%;
        height: 5rem;
        margin:  0;
        top: 10%;
        right: 1%;
        display: block;
        z-index: 5;
    }
    #timeline-top-arrow {
        position: fixed;
        right: 2rem;
        bottom: 12%;
        border-top: 25px solid transparent;
        border-right: 50px solid #000;
        border-bottom: 25px solid transparent;
        transform: rotate(90deg);
        z-index: 2;
    }
    #timeline-bottom-arrow {
        display: inline-block;
        margin: 1rem 3%;
        border-top: 25px solid transparent;
        border-left: 50px solid #000;
        border-bottom: 25px solid transparent;
        transform: rotate(90deg);
        z-index: 20;
    }
}

@media only screen and (max-width: 1080px) {
    #timeline-canva-mobile {
        width: 99%;
        position: relative;
        margin-top: 3rem;
    }

    
    #timeline-filters {
        border: #000 solid 2px;
        border-radius: 5px;
        background-color: #000;
        cursor: pointer;
        position: fixed;
        right: 1%;
        top: 10%;
        background-image: url('/public/filters.svg');
        background-size: cover;
        background-repeat: no-repeat;
        width: 2rem;
        height: 2rem;
        z-index: 2;
        box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    }
    

    #document-preview {
        height: fit-content;
    }
    

    #week-number {
        opacity: 1;
    }

    #timeline-day-mobile {
        min-height: 9rem;
        border-bottom: #000 1px solid;
    }
    #timeline-day-mobile-header{
    top: 60px;
    }



    #timeline-sidebar {
        display: none;
  }

    #timelist-content-preview div, #tilbud-content-preview div, #faktura-content-preview div, #faktura-data-preview, #tilbud-data-preview{
        font-size:xx-small;
    }
    #timelist-content-mini-preview div, #tilbud-content-mini-preview div, #faktura-content-mini-preview div, #faktura-data-preview, #tilbud-data-preview{
        font-size:xx-small;
    }
    #timelist-data-preview div, #tilbud-data-preview div, #faktura-data-preview div, #faktura-data-preview, #tilbud-data-preview{
        font-size:xx-small;
        padding: 0;
    }

    #timelist-content-mini-preview-header {
        font-size: xx-small;
    }

    #faktura-dates-preview {
        font-size: xx-small;
    }

    #timeline-day-mobile p {
        display: inline-block;
        width: 100%;
        text-align: center;
        position: relative;
    }

    #timelist-sumup, #timelist-sumup-mini{
        font-size: x-small;
        width: 60%;
        margin: 2rem auto;
    }
}
