#calendar
{
width: 100%;
}
td {
width: 14%;
position: relative;
text-align: center;
padding: 10px;
}
td:after {
content: '';
display: block;
margin-top: 100%;
}
.squareButton {
	height: 85px;
	width: 85px;
}
