#menu-new-documents {
  position: fixed;
    width: 5rem;
    height: 100%;
    display: inline-block;
    z-index: 3;
    top: 11%;
  }
  
  #left-menu, #right-menu {
    width: 100%;
  }

    
  .document-menu-item {
    vertical-align: middle;
    display: inline-block; 
    margin: 5rem 1rem 0rem 1rem;
    padding: 2px 1rem;
    height: 12rem;
    width: 12rem;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 5%;
    position: relative;
  }
  
  .document-menu-item:hover {
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 5%;
  }
  
  .document-menu-item p {
    color: #000;
    margin-top: 1rem;
    width: 10rem;
    height: 2rem;
    text-align: center;
    vertical-align: text-bottom;
    font-variant: small-caps;
  }
  
  .document-menu-item img {
    margin: 0.4rem 3rem;
    height: 6rem;
  }

  

  
  .menu-item {
    background-color: #000;
    vertical-align: middle;
    display: inline-block; 
    margin: 1rem 1rem 0rem 1rem;
    padding: 2px 0rem;
    height: 5rem;
    width: 5rem;
    box-shadow: 3px 3px 8px rgba(30,30,30,0.5), -3px -3px 8px rgba(90, 90, 90, 0.6);
    border-radius: 5%;
    position: relative;
    z-index: 4;

  }
  
  .menu-item:hover {
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(163,177,198,0.7), -5px -5px 10px rgba(255, 255, 255, 0.4);   border-radius: 5%;
    opacity: 1;
  }
  
  .menu-item p {
    color: #FFF;
    text-align: center;
    vertical-align: text-bottom;
    font-variant: small-caps;
    font-size: 0.8rem;
  }
  
  .menu-item img {
    margin: 0 25%;
    height: 50%;
    width: 50%;

  }


.menu-grid
{
  width: fit-content;
  height: 100%;
  margin: auto auto;
  display: grid;
  grid-template-columns: 30% 30% 30%;
}
.document-menu-grid
{
  width: fit-content;
  height: fit-content;
  margin: auto auto;
  display: grid;
  justify-items: center;
  grid-template-columns: 30% 30% 30%;
  padding: 0 5% 10% 5%;
}

.menu-grid-item{
 border: none;
 padding:10px;
 text-align: center;
 cursor: pointer;
 display: inline-grid;
 
}

.menu-grid-item:hover{
 border:thick #FFF solid;
 padding:10px;
}

.menu-grid-item h3 {
 margin-top: 10px;
 color: #FFF;
 
}

.menu-ico {
 width: 100%;
 cursor: pointer;
}

.login-panel {
    position: absolute;
    top: 13px;
  }
  
  
.logged-navbar {
    line-height: 3em;
  }
  .logged-navbar li {
    font-size: 1.4em;
    text-transform:uppercase;
    list-style-type: none;
    text-align: center;
    cursor: pointer;
  }
  .logged-navbar li:hover {
    transform: scale(1.2);
  }  

  .logged-panel div select {
   height: 100%;
  }
  
  .logged-panel {
    min-height: 40px;
    margin-bottom: 50px;
    padding: 0px;
    background-color: rgb(0,0,0);
    vertical-align: middle;
  }

.menu-window {
    background-color: #000;
    position: absolute;
    top: 35%;
    left: 2%;
    width: 100%;
    height: fit-content;
    padding: 3%;
    transform: translate(0, -20%);
    z-index: 10;
  }
  
  .document-menu-window{
    background: radial-gradient(circle at 24.1% 68.8%, rgb(255, 255, 255) 0%, rgb(50, 50, 50) 99.4%);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top-left-radius: 15px;
    margin: auto auto;
    width: fit-content;
    height: fit-content;
    transform: translate(0, -50%);
    z-index: 10;
    
  }

  #menu-hamburger {
    display: none;
    position: fixed;
    top: 0.1rem;
    right: 0.1rem;
    z-index: 5;
  }

  @media only screen and (max-width: 1081px) {
    #menu-new-documents {
      position: fixed;
      width: 100%;
      margin: 0 auto;
      height: auto;
      z-index: 3;
      top:auto;
      bottom: 0%;
      padding: 0;
      background-color: #000;
    }

    #menu-wrapper {
      width: fit-content;
      height: 3rem;
      margin: auto auto;
     }
    #menu-hamburger {
      display: none;
    }
    .menu-item {
      opacity: 1;
      padding-top: 0.3rem;
      width: 2.3rem;
      height: 100%;
      margin: 0 0.3rem;

    }
    .menu-item p{
      width: 100%;
      font-size: 90%;
    }
  }

  @media only screen and (min-device-width: 1280px) and (max-device-width: 1599px) {
    .menu-item {
      opacity: 1;
      padding-top: 0.3rem;
      width: 3.5rem;
      height: 3.5rem;
    }

    .menu-item p{
      font-size: x-small;
    }
  }

  @media only screen and (min-device-width: 960px) and (max-device-width: 1279px) {
    .menu-item {
      opacity: 1;
      padding-top: 0;
      width: 2.5rem;
      height: 2.5rem;
    }

    .menu-item p{
      font-size: xx-small;
    }
  }

  @media only screen and (max-width: 600px) {

    .menu-item p {
      color: #FFF;
      text-align: center;
      vertical-align: text-bottom;
      font-variant: small-caps;
      font-size: 0.3rem;
    }

    .document-menu-window{

      top: 50%;
      left: 2%;
      width: 95%;
      padding: 0;
    }
    .document-menu-grid
    {
      padding: 0 0 10% 0;
    }
    
    .document-menu-item {
      margin: 1rem 1rem 0rem 1rem;
      padding: 2px 1rem;
      height: 7rem;
      width: 7rem;
    }
    
    .document-menu-item:hover {
      cursor: pointer;
    }
    
    .document-menu-item p {
      width: 5rem;
      height: 2rem;
    }
    
    .document-menu-item img {
      margin: 0 1rem;
      height: 4rem;
    }
  }