#lc-header{ 
    padding: 1vh;
    width: 1000px;
    height: 250px;
    border: rgba(80,80,80,0.5) solid 1px;
    box-shadow: #000 0 2px 7px ;
    position: fixed;
    top: 30%;
    left: 20%;
    z-index: 90; 
    display: grid;
    grid-template-columns: 50% 50%;
    height: fit-content;
    background: rgba(0,0,0,0.5);

  }
  
  #elsa {
    width: 477px;
    height: 227px;
    margin: auto 0;
    overflow: hidden;
    display:block;
    filter: hue-rotate(23%);
    background-repeat: no-repeat;
    background-size: 104%;
    background-position-x: 30%;
    position: relative;
  }

  #elsa-continue a {
    color: #FFF;
  }
  #elsa-continue a:hover {
    color: #FFF;
    text-decoration: none;
  }

  #elsa video {
    border: rgba(80,80,80,0.5) solid 1px;
    box-shadow: 2px 2px 4px hsla(0, 0%, 100%, .9);
    width: 477px;
    height: 227px;
    transform: scale(1);
    position: absolute;
    top: 0%;
    left: 0%;
  }



  #elsa-scripted {
    width: 100%;
    min-height: 380px;
    height: fit-content;
    background-color: #FFF;
    color: #000;
    font-size: 110%;
    padding: 10% 0 0px 0 ;
    text-align: left;
    font-family: 'Michroma';
  }

  #elsa-scripted p{
    cursor: default;
    width: 96%;
    text-align: left;
    font-size: 1rem;
    line-height: 2rem;
    margin: 0 2% 5% 2%;

  }

  #elsa-continue {
    font-size: 0.8rem;
    cursor: pointer;
    width: 90%;
    height: 2rem;
    border: rgba(80,80,80,0.5) 1px solid;
    padding: 0.2rem 1rem;
    margin: 0.4rem 1rem;
  }
  #elsa-continue p {
    cursor: pointer;
  }

  #elsa-continue:hover {
    box-shadow: #000 0 2px 7px;
    border: rgba(80,80,80,0.5) 2px solid;

  }

  #elsa-popup {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 1%;
    width: 5rem;
    height: 5rem;
    margin: 0 0;
    background-color: rgba(80,80,80,0.5);
    box-shadow: #000 0 2px 7px ;
    z-index: 11;
    opacity: 1;
    text-align: left;
    border: #000 0px solid;
    border-radius: 100%;
  }

  #elsa-popup video{
    position: absolute; 
    right: 0; 
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -100;
    overflow:hidden;
    border-radius: 100%;
    background-size: cover;
  }

  #elsa-close-button {
    position: absolute;
    cursor: pointer;
    top: 1rem;
    right: 1rem;
    font-size: 20px;
    font-weight: 500;
    color: #FFF;
    text-shadow: rgba(80,80,80,0.5) 2px 2px 2px;
    z-index: 3;
    visibility: visible;
  }

  #elsa-close-button:hover {
    font-weight: 600;
    color: rgba(80,80,80,0.5);
    text-shadow: FFF 2px 2px 2px;
  }

  #elsa-mute-button {
    position: absolute;
    cursor: pointer;
    bottom: 1rem;
    left: 1rem;
    font-size: 20px;
    font-weight: 500;
    color: #FFF;
    text-shadow: rgba(80,80,80,0.5) 2px 2px 2px;
    z-index: 99;
    visibility: visible;
    width: 33px;
    height: 33px;
  }

  #elsa-mute-button:hover {
    font-weight: 600;
    color: rgba(80,80,80,0.5);
    text-shadow: FFF 2px 2px 2px;
  }

  #elsa-miniature {
    background-image: url('./popup_confirm.svg');
    background-position: 0% 30%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 2%;
    width: 96%;
    height: 96%;
    z-index: 3;
    border-radius: 100%;
    color: #FFF;
    font-size: 3rem;
    vertical-align: middle;
    text-align: center;
  }


  #mute-ico {
    background-size: contain;
    margin: 0%;
    width: 32px;
    height: 32px;
  }


  .show {
    opacity: 0;
    animation: show 0.2s 0.3s ease-out forwards;
    z-index: 0;
  }

  .hide {
    opacity: 1;
    animation: hide 0.2s 0.2s ease-out forwards;
    z-index: 3;
  }
  
  @-webkit-keyframes show{
    from { 
      opacity: 0;
      z-index: 0;
     }
    to
      { 
      opacity: 1;
      z-index: 3;

     }
}

@keyframes show{
  from { 
    opacity: 0;
    z-index: 0;

   }
  to
    { 
    opacity: 1;
    z-index: 3;

   }
}

@-webkit-keyframes hide{
  from { 
    opacity: 1;
    z-index: 3;

   }
  to
    { 
      opacity: 0;
      z-index: 0;

   }
}

@keyframes hide{
  from { 
    opacity: 1;
    z-index: 3;

   }
  to
    { 
      opacity: 0;
      z-index: 0;

   }
}

    
@media only screen and (max-width: 600px) {
  #lc-header{ 
      background: rgba(0,0,0,0.5);
      padding: 1vh;
      width: 90vw;
      height: 90vh;
      border: rgba(80,80,80,0.5) solid 1px;
      position: fixed;
      top: 90px;
      left: 2vw;
      z-index: 90; 
      height: fit-content;
      display: block;
    }

    #elsa {
      width: 100%;
      margin: 0 0;
      overflow: hidden;
      display:block;
      background-repeat: no-repeat;
      background-size: 95%;
      background-position-x: 30%;
      background-position-y: 60%;
    }
    
    #elsa video {
      box-shadow: 2px 2px 4px hsla(0, 0%, 100%, .9);
      width: 100%;
      transform: scale(1);
      position: absolute;
      top: 2%;
      left: 1%;
    }
  
    #elsa-scripted {
      width: 100%;
      min-height: 380px;
      height: fit-content;
      font-size: 110%;
      padding: 10% 0 0px 0 ;
      text-align: left;
      font-family: 'Michroma';
    }
  
    #elsa-scripted p{
      cursor: default;
      width: 96%;
      text-align: left;
      font-size: 1rem;
      line-height: 2rem;
      margin: 0 2% 5% 2%;
  
    }
  
    #elsa-continue {
      font-size: 0.8rem;
      cursor: pointer;
      width: 90%;
      height: 2rem;
      padding: 0.2rem 1rem;
      margin: 0.4rem 1rem;
    }
    #elsa-continue p {
      cursor: pointer;
    }
  
    #elsa-continue:hover {
  
    }
  
  
    #elsa-popup {
      cursor: pointer;
      position: fixed;
      top: 1%;
      left: 70%;
      width: 5rem;
      height: 5rem;
      margin: 0 0;
      z-index: 11;
      opacity: 1;
      text-align: left;
      border: #000 0px solid;
      border-radius: 100%;
    }
  
    #elsa-close-button {
      position: absolute;
      cursor: pointer;
      top: 1rem;
      right: 1rem;
      font-size: 20px;
      font-weight: 500;
      color: #000;
      text-shadow: #FFF 2px 2px 2px;
      z-index: 3;
      visibility: visible;
    }
  
    #elsa-close-button:hover {
      font-weight: 600;
      color: #FFF;
      text-shadow: #14e2dd 2px 2px 2px;
    }
  
    #elsa-miniature {
      background-position: 0% 30%;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 2%;
      width: 96%;
      height: 96%;
      z-index: 3;
      border-radius: 100%;
    }
    #elsa-mute-button {
      width: 33px;
      height: 33px;
    }
  
    #mute-ico {
      width: 32px;
      height: 32px;
    }
  
    .show {
      opacity: 0;
      animation: show 0.2s 0.3s ease-out forwards;
      z-index: 0;
    }
  
    .hide {
      opacity: 1;
      animation: hide 0.2s 0.2s ease-out forwards;
      z-index: 3;
    }
   
}