.list-window-background{
    background-color: rgba(0,0,0,0.8);
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      z-index: 30;
    }


#list-window {
    position: absolute;
    top: 9rem;
    left: 0;
    width: 25rem;
    background-color: #000;
    box-shadow: 3px 3px 8px rgba(80,80,80,0.5), -3px -3px 8px rgba(200, 200, 200, 0.6);
    border-radius: 0 0 5px 5px;
    z-index: 31;
}

#client-list, #project-list, #doc-list, #message-list, #task-list{
    background-color: #FFF;
    margin: 0 0.5rem;
    width: 24rem;
    height: 13rem;
    overflow-y: auto;
}
#search-results{
    background-color: #FFF;
    margin: 0 0.5rem;
    width: 24rem;
    max-height: 30rem;
    overflow-y: auto;
}

#client-list-item, #project-list-item, #document-list-item, #message-list-item, #task-list-item{
    border-bottom: #000 solid 1px;
    width: 100%;
    height: 3rem;
    cursor: pointer;
}
#project-list-item, #client-list-item, #document-list-item {
    display: grid;
    grid-template-columns: 80% 20%;
    column-gap: 0;
    position: relative;
}

#client-list-item:hover, #project-list-item:hover, #document-list-item:hover, #message-list-item:hover, #task-list-item:hover{
    border-bottom: #000 solid 1px;
    background-color: #000;
    color: #FFF;
}


#list-header {
    background-color: rgb(223,223,223); 
    margin: 0.5rem 0.5rem 0 0.5rem; 
    font-style: italic;
    font-size: smaller;
    border-bottom: #000 1px solid;
    cursor: default;
    font-variant: small-caps;
}

#search-list-header {
    background-color: rgb(223,223,223); 
    margin: 0.5rem 0 0.5rem 0; 
    font-style: italic;
    font-size: smaller;
    border-bottom: #000 1px solid;
    cursor: default;
    font-variant: small-caps;
}

#add-new-project {
    width: 100%;
    margin: 0.5rem 0.5rem 0.5rem 1rem;
    display: inline-block;
}

.ico-label {
    color: #FFF;
    margin: 0 0.6rem;
    width: 3rem;
    font-variant: small-caps;
    align-items: center;
    text-align: center;
}

#empty-list {
    color: #000;
}

.search{
    padding-top: 3rem;
    top: 9rem;
    min-height: 6rem;
}

.projects, .clients {
    height: 23rem;
    top: 8rem;
}

#list-ico-set, #project-ico-set{
    display: inline;
    min-width: 2rem;
    max-width: 70%;
}


@media only screen and (max-width: 1020px) {
    #list-window {
        position: relative;
        margin: auto auto;
        width: 25rem;
        background-color: #000;
        box-shadow: 3px 3px 8px rgba(80,80,80,0.5), -3px -3px 8px rgba(200, 200, 200, 0.6);
        border-radius: 0 0 5px 5px;
        z-index: 31;
    }
}